'use client';

import { useMemo, useEffect, useReducer, useCallback } from 'react';

// eslint-disable-next-line import/no-cycle
import axios, { endpoints } from 'src/utils/axios';

import { AuthContext } from './auth-context';
import { setCookie, isValidToken, setLocalStorage } from './utils';
import { AuthUserType, ActionMapType, AuthStateType } from '../../types';

// ----------------------------------------------------------------------

// NOTE:
// We only build demo at basic level.
// Customer will need to do some extra handling yourself if you want to extend the logic and other features...

// ----------------------------------------------------------------------

enum Types {
  INITIAL = 'INITIAL',
  LOGIN = 'LOGIN',
  REGISTER = 'REGISTER',
  LOGOUT = 'LOGOUT',
}

type Payload = {
  [Types.INITIAL]: {
    user: AuthUserType;
    permissions: string[];
  };
  [Types.LOGIN]: {
    user: AuthUserType;
    permissions: string[];
  };
  [Types.REGISTER]: {
    user: AuthUserType;
    permissions: string[];

  };
  [Types.LOGOUT]: undefined;

};

type ActionsType = ActionMapType<Payload>[keyof ActionMapType<Payload>];

// ----------------------------------------------------------------------

const initialState: AuthStateType = {
  user: null,
  permissions: [],
  loading: true,
};

const reducer = (state: AuthStateType, action: ActionsType) => {
  if (action.type === Types.INITIAL) {
    return {
      loading: false,
      user: action.payload.user,
      permissions: action.payload.permissions
    };
  }
  if (action.type === Types.LOGIN) {
    return {
      ...state,
      user: action.payload.user,
      permissions: action.payload.permissions
    };
  }
  if (action.type === Types.REGISTER) {
    return {
      ...state,
      user: action.payload.user,
      permissions: action.payload.permissions
    };
  }
  if (action.type === Types.LOGOUT) {
    return {
      ...state,
      user: null,
    };
  }
  return state;
};

// ----------------------------------------------------------------------

export const KEY_accessToken = 'accessToken';

type Props = {
  children: React.ReactNode;
};

export function AuthProvider({ children }: Props) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const initialize = useCallback(async () => {
    try {
      // const accessToken = sessionStorage.getItem(KEY_accessToken);
      const accessToken = localStorage.getItem(KEY_accessToken);

      if (accessToken && isValidToken(accessToken)) {
        setLocalStorage(KEY_accessToken, accessToken);

        const res = await axios.get(endpoints.auth.me);
        const { user } = res.data.payload;
        console.log('useruseruser', user)
        const permissionsSlugs: string[] = user?.allPermissions.map(({ slug }: { slug: string }) => slug)



        console.log('AuthProvider user', user);
        dispatch({
          type: Types.INITIAL,
          payload: {
            user: {
              ...user,
            },
            permissions: permissionsSlugs,

          },
        });
      } else {
        dispatch({
          type: Types.INITIAL,
          payload: {
            user: null,
            permissions: []
          },
        });
      }
    } catch (error) {
      console.error(error);
      dispatch({
        type: Types.INITIAL,
        payload: {
          user: null,
          permissions: []
          ,
        },
      });
    }
  }, []);

  useEffect(() => {
    initialize();
  }, [initialize]);

  // LOGIN
 const login = useCallback(async (username: string, password: string) => {
  try {
    const data = {
      username,
      password,
    };
    console.log('data',data)

    const res = await axios.post(endpoints.auth.login, data);
    console.log('res.data.payload',res.data.payload)

    const { user, token } = res.data.payload;
    const permissionsSlugs: string[] = user?.allPermissions.map(({ slug }: { slug: string }) => slug)
    const { accessToken } = token;
    setLocalStorage(KEY_accessToken, accessToken);
  

    dispatch({
      type: Types.LOGIN,
      payload: {
        user: {
          ...user,
          accessToken,
        },
        permissions: permissionsSlugs,
      },
    });
  } catch (error) {
    console.error(error);
    // handle the error here
  }
}, []);
  // REGISTER
  const register = useCallback(
    async (username: string, password: string, firstName: string, lastName: string) => {
      const data = {
        username,
        password,
        firstName,
        lastName,
      };

      const res = await axios.post(endpoints.auth.register, data);

      const { user, token } = res.data.payload;
      const permissionsSlugs: string[] = user?.allPermissions.map(({ slug }: { slug: string }) => slug)
      const { accessToken } = token;

      // sessionStorage.setItem(KEY_accessToken, accessToken);
      setLocalStorage(KEY_accessToken, accessToken);


      dispatch({
        type: Types.REGISTER,
        payload: {
          user: {
            ...user,
            accessToken,
          },
          permissions: permissionsSlugs,
        },
      });
    },
    []
  );

  // LOGOUT
  const logout = useCallback(async () => {
    setCookie(KEY_accessToken, null);
    setLocalStorage(KEY_accessToken, null);

    dispatch({
      type: Types.LOGOUT,
    });
  }, []);

  // ----------------------------------------------------------------------

  const checkAuthenticated = state.user ? 'authenticated' : 'unauthenticated';

  const status = state.loading ? 'loading' : checkAuthenticated;

  const memoizedValue = useMemo(
    () => ({
      user: state.user,
      permissions: state.user ? state.permissions : [],
      method: 'jwt',
      loading: status === 'loading',
      authenticated: status === 'authenticated',
      unauthenticated: status === 'unauthenticated',
      //
      login,
      register,
      logout,
    }),
    [login, logout, register, state.permissions, state.user, status]
  );

  return <AuthContext.Provider value={memoizedValue}>{children}</AuthContext.Provider>;
}
