import i18next from 'i18next';
import { allLangs } from 'src/locales';

export function getTranslation(
  object: { [key: string]: string },
  languageCode?: string,
  defaultName: string = 'loading...'
): string {
  if (!object || Object.keys(object).length === 0) return "";  
  if (!object) return defaultName;

  if (languageCode && object[languageCode]) {
    return object[languageCode];
  }

  const currentLanguage = i18next.resolvedLanguage;
  if (currentLanguage && object[currentLanguage]) {
    return object[currentLanguage];
  }

  const fallbackLanguage = allLangs.find((language) => object[language.value]);
  if (fallbackLanguage) {
    return object[fallbackLanguage.value];
  }

  return defaultName;
}